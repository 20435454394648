import { createRouter, createWebHistory } from "vue-router";

const routes = [
      {
    path: "/Regles-de-Suppression-de-compte-shoppmoney",
    name: "Regles-de-Suppression-de-compte-shoppmoney",
    component: () => import("../views/ReglesDeSuppressionDeCompteShoppmoney.vue"),

  },
    {
    path: "/Politique-de-Confidentialite-pro-shoppmoney",
    name: "Politique-de-Confidentialite-pro-shoppmoney",
    component: () => import("../views/PolitiqueDeConfidentialitePro.vue"),
  },
      {
    path: "/condition-generales-pro-shoppmoney",
    name: "condition-generales-pro-shoppmoney",
    component: () => import("../views/ConditionGeneralePro.vue"),
  },
    {
    path: "/Politique-de-Confidentialite-App",
    name: "Politique-de-Confidentialite-App",
    component: () => import("../views/PolitiqueDeConfidentialiteApp.vue"),
  },
      {
    path: "/condition-generales-App",
    name: "condition-generales-App",
    component: () => import("../views/ConditionGeneraleApp.vue"),
  },
  {
    path: "/Mention-legales",
    name: "Mention-légales",
    component: () => import("../views/MentionLegales.vue"),
  },
  {
    path: "/contacter-nous",
    name: "contact",
    component: () => import("../views/Contact.vue"),
  },

  {
    path: "/Presence_en_ligne",
    name: "Présence en ligne",
    component: () => import("../views/PresenceEnLigne.vue"),
  },
  {
    path: "/payment_plan",
    name: "payment plan",
    component: () => import("../views/PaymentPlan.vue"),
  },
  {
    path: "/simplicite_utilisation",
    name: "simplicité utilisation",
    component: () => import("../views/SimplicitéUtilisation.vue"),
  },
  {
    path: "/qui_sommes_nous",
    name: "Qui Sommes Nous",
    component: () => import("../views/QuiSommesNous.vue"),
  },
  {
    path: "/client",
    name: "Client",
    component: () => import("../views/Client.vue"),
  },
  {
    path: "/professionnel",
    name: "Professionnel",
    component: () => import("../views/Professionnel.vue"),
  },
  {
    path: "/Creativite_illimitee",
    name: "Créativité illimitée",
    component: () => import("../views/CreativiteIllimitee.vue"),
  },
  {
    path: "/Reussite_garantie",
    name: "Réussite garantie",
    component: () => import("../views/ReussiteGarantie.vue"),
  },
  {
    path: "/independence_marketing",
    name: "independence marketing",
    component: () => import("../views/IndependenceMarketing.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
        meta: { description: 'Votre application pour découvrir et interagir avec les commerçants de votre ville . Suivez vos commerçants préférés et profitez de leurs offres ou services.' }
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
  mode: "history",
  base: "http://www.vitrine.shoppmoney.cloud/",
  routes,
});

export default router;
